var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex sa-button",
      attrs: { role: "button", "e2e-signin-seamless": "" },
      on: {
        click: function ($event) {
          return _vm.satosaLogin()
        },
      },
    },
    [
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "d-flex align-items-center sa-button-text text-truncate",
        },
        [
          _c(
            "div",
            { staticClass: "text-truncate" },
            [
              _vm._t("default", function () {
                return [_vm._v("Access through your institution")]
              }),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sa-button-logo-wrap" }, [
      _c("img", {
        staticClass: "sa-button-logo",
        attrs: {
          src: "https://service.seamlessaccess.org/sa-white.svg",
          alt: "Seamless Access Logo",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }